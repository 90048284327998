.epargne {
  text-decoration : underline;
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
}

.card-format {
  display: flex;
  align-items: center;
  @media (min-width: 1100px) {
    flex-direction: row;
  }
  flex-direction: column;
}

.TwoCards {
  display: flex;
  flex-direction: column;
  @media (min-width: 1100px) {
    flex-direction: row;
  }
}

.under-element-of-card {
  display: flex;
  justify-content: center;
  flex-direction: row;
  background-color: white;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 12px;
  height: 40px;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 16px;
  gap: 8px;
  max-width: fit-content;
  @media (min-width: 768px) {
    min-width: 250px;
    padding-left: 0px;
    padding-right: 0px
  }
}

.onoffswitch-checkbox {
  margin-top: 20px;
}

#champs {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 10px;
}

#plan-epargne-retraite {
  background-color:  #c9d4e8;
  flex-basis: 0;
  flex-grow: 1;
  width: 350px;
}

#assurance-vie {
  background-color:  #d5d0e2;
  flex-basis: 0;
  flex-grow: 1;
  width: 350px;
}

#assurance-emprunteur {
  background-color:   #ccf2ff;
  flex-basis: 0;
  flex-grow: 1;
  width: 350px;
  }

#PrevPro {
  background-color:  #b3ffe0;
  flex-basis: 0;
  flex-grow: 1;
  width: 350px;
}

#Pinel {
  background-color:  #ffe0b3;
  flex-basis: 0;
  flex-grow: 1;
  width: 350px;
}

#Mut {
  background-color:  #ffb3cc;
  flex-basis: 0;
  flex-grow: 1;
  width: 350px;
}

.CardPlacement {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Onglet {
  display: flex;
  margin-right: 30px;
}

.ListProduit {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.LessButton {
  background-color: white;
  margin-left: 10px;
  width: 30px;
  border-radius: 12px;
}

.MoreButton {
  margin-right: 10px;
  width: 30px;
  background-color: white;
  border-color: rgba(227, 227, 227, 0.8);
  border-radius: 12px;
}
