.alert {
  padding: 20px;
  position: fixed;
  top: 1vh;
  right: 1vw;
  background-color: #f8d7da;
  color: #721c24;
  border-color: #721c24;
  border-radius: 5px;
  border: 1px;

  &.enabled {
    display: block;
  }

  &.disabled {
    display: none;
  }

}
