.Input {
  background: white;
  display: flex;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 12px;
  height: 40px;
  width: 100%;
  font-size: 16px;
  gap: 8px;
  @media (min-width: 768px) {
    // min-width: 250px;
  }
  padding-left: 10px;
}

.obligatoire {
  padding: 0 5px;
  color: red;
}
