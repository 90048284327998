.phone-format {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;
}

.button-verif {
  margin: 30px;
}

.title-phone {
  margin-bottom: 40px;
}