.Container {
  bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 94%;
  margin-right: 3%;
  margin-left: 3%;
}

.btn-holder {
  justify-content: flex-end;
  display: flex;
  position: relative;
  bottom: 0px;
  margin-left: 16px;
  @media (min-width: 768px) {
    margin-left: 0px;
  }
}

.btn-start {
  justify-content: flex-start;
  display: flex;
  position: relative;
  bottom: 0px;
  right: 0px;
  margin-right: 16px;
  @media (min-width: 768px) {
    margin-right: 0px;
  }
}

.Etape-left {
  justify-content: center;
  align-items: center;
  padding: 20px 26px;
  gap: 10px;
  background: #002da7;
  border-radius: 6px;
  font-style: normal;
  font-family: "Arial", sans-serif;
  color: white;
  border-style: solid;
  font-weight: 1000;
}

.Etape-right {
  justify-content: center;
  align-items: center;
  padding: 20px 26px;
  gap: 10px;
  background: #ffffff;
  border-radius: 6px;
  font-style: normal;
  font-family: "Arial", sans-serif;
  color: #002da7;
  border-style: solid;
  font-weight: 1000;
}

.Etape-right:active {
  border-color: #002da7;
}

.Etape-right:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}

.Etape-left:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}

.Etape-left:active{
  border-color: #ffffff;
}