
.drop{
  width: fit-content;
}

.Dropdown {
  margin-left: 10px;
  // width: 100%;
  width: fit-content;
  margin-right: 10px;
  flex-shrink: 0;
}

.Dropdown-select {
  width: 250px;
  border-radius: 4px;
  width: 265px;
}

.Dropdown-select select{
  height: 42px;
  padding: 10px 10px;
  background-color: white;
  line-height: 18px;
  font-size: 16px;
  width: 100%;
  border-radius: 12px;
  border: 2px solid #ccc;
  -webkit-appearance: none;
  appearance: none;
}

.Dropdown-select:after{
  position: absolute;
  right: 8px;
  top: 50%;
  margin-top: -4px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  pointer-events: none;
}

.Dropdown-select select:focus{
  border: 2px solid green;
}