
.actif-immobilier{

  .ActifImmos {
    margin: 20px 10px;
    position: center;
  }
  
  .Actif {
    display: flex;
    margin-top: 10px;
    align-items: center;
    position: relative;
  }
  .TextBox {
    margin-right: 10px;
  }
  
  #MyInput {
    // min-width: 10%;
  }
  #MyDrop {
    min-width: 100px;
    width: 100%;
  }
  
  .drop {
    margin-right: 10px;
    flex-shrink: 0;
  }

  .type-house{
    width: 200px !important;
  }

  .value-acquisition{
    width: 170px !important;
  }

  .capital{
    width: 170px !important;
  }

  .mensualite{
    width: 170px !important;
  }

  .loyer{
    width: 170px !important;
  }
}

