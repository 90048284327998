.Card {
  display: flex;
  background-color: ghostwhite;
  flex-direction: column;
  align-items: center;
  border: solid #ccc;
  border-radius: 0.5rem;
  border-width: 1px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: fit-content;
  margin-bottom: 30px;
  margin-right: 16px;
  margin-left: 16px;
  @media (min-width: 768px) {
    margin-right: 16px;
    margin-left: 16px;
    margin-bottom: 30px;

  }
  @media (min-width: 1200px) {
    margin-right: 16px;
    margin-left: 16px;
  }
}