#MyInput {
  // min-width: 10%;
}
#MyDrop {
  min-width: 10px;
  width: 100%;
}

.Actif{
  display: flex;
}

.format-epargne {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.DropDownEpargne {
  display: flex;
  margin-bottom: 10px;
}

.DeleteAllEpargne {
  margin-left: 33px;
}
