.Sexe {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: x-large;
  font-weight: bold;
  margin-top: 1.5em;
  padding-left: 10px;
  margin-bottom: 1em;
}

.radio-container-gender{
  margin-right: 12px;
  display: flex;
  align-items: center;

  input{
    margin-right: 10px;
  }
}

/*.Prenom {
  background: white;
  display: flex;
  align-items: center;
  border: 2px solid grey;
  border-radius: 12px;
  align-self: stretch;
}
*/
.line {
  flex-direction: row;
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}

.Input {
  margin-right: 10px;
  margin-left: 10px;
}

.Profession {
  margin-top: 60px;
}

#Phone {
  background: white;
  display: flex;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 12px;
  height: 40px;
  width: 100%;
  font-size: 16px;
  gap: 8px;
  margin-left: 10px;
  max-width: fit-content;
  @media (min-width: 768px) {
    min-width: 250px;
  }
}

.ButtonModal {
  background: white;
  display: flex;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 12px;
  height: 40px;
  width: 100%;
  font-size: 16px;
  gap: 8px;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
  max-width: fit-content;
  @media (min-width: 768px) {
    min-width: 250px;
  }
  padding-left: 10px;
}

.ModalInterface {
  display: flex;
  align-items: center;
  flex-direction: column;
}

#modal {
  margin-bottom: 10px;
}

.ModalStyle {
  position: absolute;
  top: 50%;
  padding-top: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 12px;

  .Input{
    width: 75%;
  }

}
.close-modal-container{
  width: 96%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#button-close-modal {
  background-color: #fff;
  color: rgb(83, 83, 83);
  outline: 0;
  border: none;
  cursor: pointer;
}