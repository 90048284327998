.NavBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: white;
  box-shadow: 0px 0px 16px rgba(22,56,134,0.12);
}

.LogoLexem {
  width: 50%;
}

.RightButton {
  display: flex;
  margin-right: 30px;
}