.revenus-foyer{
    .Dropdown-select{
        width: fit-content;
    }
    
    .revenus-foyer{
        width: 200px !important;
    }

    .revenus-ans{
        width: 175px !important;
    }

    .revenus-mois{
        width: 175px !important;
    }

    .revenus-ans-conjoint{
        width: 175px !important;
    }
}