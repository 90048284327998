.Loader{
    position: fixed;
    background-color: #015de6;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    height: 100vh;
    width: 100vw;
    transition: 600ms;
    z-index: 10000;

    &.enabled{
        top: 0;
        left: 0;
    }

    &.disabled{
        top: -100vh;
        left: 0;
    }
}