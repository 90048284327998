.goodbyeText {
  margin-left: 10px;
  margin-right: 10px;
}

.rdvDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.goodbyeBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  margin-top: 10%;
}
