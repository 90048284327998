.Revenus {
  align-items: center;
  display: flex;
  position: relative;
  flex-direction: row;
}

.Revenu {
  align-items: center;
  display: flex;
  position: relative;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
}

.Total {
  align-items: center;
  display: flex;
  width: auto;
  position: relative;
  flex-direction: row;
  margin-right: 10px;
  margin-left: 10px;
}

.ResumeSituation {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 10%;
}

#Epargne {
  margin-left: 10px;
  margin-right: 30px;
}

#Immo {
  margin-left: 10px;
  margin-right: 15px;
}

p {
  font-size: 17px;
}

.Money {
  font-weight: 900;
  color: #002da7;
  font-size: 30px;
}
