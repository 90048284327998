
#Phone {
  background: white;
  display: flex;
  align-items: center;
  border: 2px solid #ccc;
  height: 40px;
  width: 100%;
  font-size: 16px;
  gap: 8px;
  margin-left: 10px;
  max-width: fit-content;
  @media (min-width: 768px) {
    min-width: 250px;
  }
  padding-left: 10px;
}

.date{
  width: 200px !important;
}