.format-objectif{
  margin: 0 5vw;

  .fonction-exerce{
    margin-right: 10px;
  }

}

.checking {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 20px;
}

.check {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  margin-left: 20px;
}

.checker {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
}

.fond-investis {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.format-objectif {
    display: flex;
    justify-content: center;
    flex-direction: column;
}