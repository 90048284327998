.answer-choice {
  display: flex;
  flex-direction: column;
}

.page-format-un-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.questions {
  width: initial;
  margin-left: 10px;
  margin-right: 10%;
  margin-bottom: 1%;
}

#FourthQuestion {
  display: flex;
  justify-content: flex-start;
}

table,
th,
td {
  border: 1px solid;
}

.profil-investisseur{
  td, th{
    padding: 7px;
  }

}

.rdv-date{
  width: 90%;
}

th {
  text-align: left;
}
table {
  border-collapse: collapse;
  width: 100%;
  overflow: hidden;
}
