.page-format {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.format-sexe {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 1200px) {
    flex-direction: row;
  }
}