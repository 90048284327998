.MyButton{
  justify-content: center;
  align-items: center;
  padding: 20px 26px;
  gap: 10px;
  background: #ffffff;
  border-radius: 6px;
  font-style: normal;
  font-family: "Arial", sans-serif;
  color: #002da7;
  border-style: solid;
  font-weight: 1000;
}

.MyButton:active {
  border-color: #002da7;
}

.MyButton:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}

.MyButton-inv {
  justify-content: center;
  align-items: center;
  padding: 20px 26px;
  gap: 10px;
  background: #002da7;
  border-radius: 6px;
  font-style: normal;
  font-family: "Arial", sans-serif;
  color: #ffffff;
  border-style: solid;
  font-weight: 1000;
}

.MyButton-inv:active {
  border-color: white;
}

.MyButton-inv:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}