.Data {
    margin-bottom: 20px;
    position: center;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .Delete {
    margin-left: 10px;
  
  }
  
  .Manage {
    margin-bottom: 20px;
    position: center;
    display: flex;
    margin-left: 10px;
    flex-direction: row;
    align-items: center;
  }
  
  .AddingButton {
    margin-right: 10px;
  }
  
  .DeleteIcon {
    background: transparent;
    cursor: pointer;
    border: none !important;
  }

.DropDownProtection {
  display: flex;
  margin-bottom: 10px;
}

.DeleteAllProtection {
  margin-left: 15px;
}